<template>
  <div>
    <form-wizard
      color="#ea5455"
      ref="refFormWizard"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Create Session"
      back-button-text="Previous"
      :hide-buttons="navHidden"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Session Details -->
      <tab-content title="Session Details" :before-change="validationForm">
        <validation-observer ref="sessionRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Session Details</h5>
              <small class="text-muted"> Enter The Session Details. </small>
            </b-col>
            <b-col md="6">
              <b-form-group label="Name" label-for="SessionName">
                <validation-provider #default="{ errors }" name="SessionName" rules="required">
                  <b-form-input id="SessionName" v-model="editingSession.name" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6"></b-col>
            <b-col md="6">
              <b-form-group label="Status" label-for="status">
                <validation-provider #default="{ errors }" name="Status" vid="Status" rules="required">
                  <v-select
                    id="status"
                    v-model="editingSession.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sessionStatus"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Visibility" label-for="visibility">
                <validation-provider #default="{ errors }" name="Visibility" rules="required">
                  <v-select
                    id="visibility"
                    v-model="editingSession.visibility"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sessionVisibility"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- session courses / course list -->
      <tab-content title="Session Courses" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Session Courses</h5>
              <small class="text-muted">Enter The Courses Included In This Session.</small>

              <div class="d-inline-block float-right">
                <b-button @click="startCreateCourse" variant="primary">Add New Course</b-button>
              </div>
            </b-col>
            <b-col md="12">
              <b-table small :fields="sessionCoursesFields" :items="sessionCourses" responsive="sm">
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
                </template>

                <template #cell(order_status)="data">
                  <b-badge pill :variant="data.value.variant">
                    {{ data.value.status }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(instructor)="data">
                  <span v-if="data.value && data.value.name">{{ data.value.name }}</span>
                  <span v-else>No Instructor Assigned</span>
                </template>

                <!-- A virtual composite column -->
                <template #cell(profiles)="data"> {{ data.value.length }} total </template>

                <!-- A virtual composite column -->
                <template #cell(classes)="data"> {{ data.value.length }} total </template>

                <!-- operation that will be completed for course -->
                <template #cell(operation)="data"> {{ data.value }} </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item @click="startEditCourse(data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="startDeleteCourse(data.item)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- course edit -->
      <tab-content v-if="enableCourseEdit" title="Edit Course" :before-change="validationFormCourseEdit">
        <validation-observer ref="courseEditRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Edit A Course</h5>
              <small class="text-muted">Enter The Details For This Course.</small>

              <div class="d-inline-block float-right">
                <b-button @click="cancelCourseEdit" variant="outline-secondary" class="mr-1">
                  <feather-icon icon="ArrowLeftIcon" />
                  Return
                </b-button>
                <b-button @click="startEditCourseSave" variant="primary">Modify Course Settings</b-button>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group label="Course Name" label-for="courseName">
                <validation-provider #default="{ errors }" name="Course Name" rules="required">
                  <b-form-input
                    id="courseName"
                    v-model="editingCourse.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="CompTIA Security+"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Subject" label-for="courseSubject">
                <validation-provider #default="{ errors }" name="Subject" vid="Subject" rules="required">
                  <v-select
                    id="status"
                    v-model="editingCourse.subject"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="coursesSubjects"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Status" label-for="courseStatus">
                <validation-provider #default="{ errors }" name="Status" vid="Status" rules="required">
                  <v-select
                    id="status"
                    v-model="editingCourse.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="courseStatusOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Visibility" label-for="courseVisibility">
                <validation-provider #default="{ errors }" name="Visibility" rules="required">
                  <v-select
                    id="courseVisibility"
                    v-model="editingCourse.visibility"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="courseVisibilityOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <!--              <b-card-->
              <!--                  no-body-->
              <!--                  class="mb-0"-->
              <!--              >-->

              <!--                <div class="m-2">-->

              <!--                  &lt;!&ndash; Table Top &ndash;&gt;-->
              <!--                  <b-row>-->

              <!--                    &lt;!&ndash; Per Page &ndash;&gt;-->
              <!--                    <b-col-->
              <!--                        cols="12"-->
              <!--                        md="6"-->
              <!--                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
              <!--                    >-->
              <!--                      <label>Show</label>-->
              <!--                      <v-select-->
              <!--                          v-model="perPage"-->
              <!--                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                          :options="perPageOptions"-->
              <!--                          :clearable="false"-->
              <!--                          class="per-page-selector d-inline-block mx-50"-->
              <!--                      />-->
              <!--                      <label>entries</label>-->
              <!--                    </b-col>-->

              <!--                    &lt;!&ndash; Search &ndash;&gt;-->
              <!--                    <b-col-->
              <!--                        cols="12"-->
              <!--                        md="6"-->
              <!--                    >-->
              <!--                      <div class="d-flex align-items-center justify-content-end">-->
              <!--                        <b-form-input-->
              <!--                            v-model="searchQuery"-->
              <!--                            class="d-inline-block mr-1"-->
              <!--                            placeholder="Search..."-->
              <!--                        />-->
              <!--                        <b-button-->
              <!--                            variant="primary"-->
              <!--                            @click="isAddNewClassSidebarActive = true"-->
              <!--                        >-->
              <!--                          <span class="text-nowrap">Add Class</span>-->
              <!--                        </b-button>-->
              <!--                      </div>-->
              <!--                    </b-col>-->
              <!--                  </b-row>-->

              <!--                </div>-->

              <!--                <b-table-->
              <!--                    ref="refClassListTable"-->
              <!--                    class="position-relative"-->
              <!--                    :items="fetchClasses"-->
              <!--                    responsive-->
              <!--                    :fields="tableColumns"-->
              <!--                    primary-key="id"-->
              <!--                    :sort-by.sync="sortBy"-->
              <!--                    show-empty-->
              <!--                    empty-text="No matching records found"-->
              <!--                    :sort-desc.sync="isSortDirDesc"-->
              <!--                >-->

              <!--                  &lt;!&ndash; Column: Class &ndash;&gt;-->
              <!--                  <template #cell(name)="data">-->
              <!--                    <b-link-->
              <!--                        :to="{ name: 'apps-roster-class-view', params: { id: data.item.id } }"-->
              <!--                        class="font-weight-bold d-block text-nowrap"-->
              <!--                    >-->
              <!--                      {{ data.item.name }}-->
              <!--                    </b-link>-->
              <!--                    <small class="text-muted">{{ (data.item.classes || []).length }} total classes</small>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Live Classes &ndash;&gt;-->
              <!--                  <template #cell(date)="data">-->
              <!--                    <div class="text-nowrap">-->
              <!--            <span class="align-text-top text-capitalize">-->
              <!--              {{ moment(data.item.date).format('MMMM Do YYYY (dddd)') }}-->
              <!--            </span>-->
              <!--                    </div>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Live Classes &ndash;&gt;-->
              <!--                  <template #cell(time)="data">-->
              <!--                    <div class="text-nowrap">-->
              <!--            <span class="align-text-top text-capitalize">-->
              <!--              {{ moment(data.item.timeStart, "HH:mm:ss").format('hh:mm A') }}-->
              <!--              - -->
              <!--              {{ moment(data.item.timeEnd, "HH:mm:ss").format('hh:mm A') }}-->
              <!--            </span>-->
              <!--                    </div>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Live Classes &ndash;&gt;-->
              <!--                  <template #cell(live_classes)="data">-->
              <!--                    <div class="text-nowrap">-->
              <!--            <span class="align-text-top text-capitalize">{{-->
              <!--                data.item.classes.filter(clazz => clazz.status === 'live').length-->
              <!--              }}</span>-->
              <!--                    </div>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Visibility &ndash;&gt;-->
              <!--                  <template #cell(type)="data">-->
              <!--                    <b-badge-->
              <!--                        pill-->
              <!--                        :variant="`light-${data.item.type === 'class' ? 'success' : 'warning'}`"-->
              <!--                        class="text-capitalize"-->
              <!--                    >-->
              <!--                      {{ data.item.type }}-->
              <!--                    </b-badge>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Visibility &ndash;&gt;-->
              <!--                  <template #cell(location)="data">-->
              <!--                    <b-badge-->
              <!--                        pill-->
              <!--                        :variant="`light-${data.item.location === 'virtual' ? 'primary' : 'secondary'}`"-->
              <!--                        class="text-capitalize"-->
              <!--                    >-->
              <!--                      {{ data.item.location }}-->
              <!--                    </b-badge>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Visibility &ndash;&gt;-->
              <!--                  <template #cell(visibility)="data">-->
              <!--                    <b-badge-->
              <!--                        pill-->
              <!--                        :variant="`light-${resolveClassVisibilityVariant(data.item.visibility)}`"-->
              <!--                        class="text-capitalize"-->
              <!--                    >-->
              <!--                      {{ data.item.visibility.split('_').join(' ') }}-->
              <!--                    </b-badge>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Status &ndash;&gt;-->
              <!--                  <template #cell(status)="data">-->
              <!--                    <b-badge-->
              <!--                        pill-->
              <!--                        :variant="`light-${resolveClassStatusVariant(data.item.status)}`"-->
              <!--                        class="text-capitalize"-->
              <!--                    >-->
              <!--                      {{ data.item.status.split('_').join(' ') }}-->
              <!--                    </b-badge>-->
              <!--                  </template>-->

              <!--                  &lt;!&ndash; Column: Actions &ndash;&gt;-->
              <!--                  <template #cell(actions)="data">-->
              <!--                    <b-dropdown-->
              <!--                        variant="link"-->
              <!--                        no-caret-->
              <!--                        :right="$store.state.appConfig.isRTL"-->
              <!--                    >-->

              <!--                      <template #button-content>-->
              <!--                        <feather-icon-->
              <!--                            icon="MoreVerticalIcon"-->
              <!--                            size="16"-->
              <!--                            class="align-middle text-body"-->
              <!--                        />-->
              <!--                      </template>-->

              <!--                      <b-dropdown-item :to="{ name: 'apps-roster-class-edit', params: { id: data.item.id } }">-->
              <!--                        <feather-icon icon="EditIcon"/>-->
              <!--                        <span class="align-middle ml-50">Edit</span>-->
              <!--                      </b-dropdown-item>-->

              <!--                      <b-dropdown-item>-->
              <!--                        <feather-icon icon="TrashIcon"/>-->
              <!--                        <span class="align-middle ml-50">Delete</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                    </b-dropdown>-->
              <!--                  </template>-->

              <!--                </b-table>-->
              <!--                <div class="mx-2 mb-2">-->
              <!--                  <b-row>-->

              <!--                    <b-col-->
              <!--                        cols="12"-->
              <!--                        sm="6"-->
              <!--                        class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
              <!--                    >-->
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{-->
              <!--                dataMeta.of-->
              <!--              }} entries</span>-->
              <!--                    </b-col>-->
              <!--                    &lt;!&ndash; Pagination &ndash;&gt;-->
              <!--                    <b-col-->
              <!--                        cols="12"-->
              <!--                        sm="6"-->
              <!--                        class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
              <!--                    >-->

              <!--                      <b-pagination-->
              <!--                          v-model="currentPage"-->
              <!--                          :total-rows="totalClasses"-->
              <!--                          :per-page="perPage"-->
              <!--                          first-number-->
              <!--                          last-number-->
              <!--                          class="mb-0 mt-1 mt-sm-0"-->
              <!--                          prev-class="prev-item"-->
              <!--                          next-class="next-item"-->
              <!--                      >-->
              <!--                        <template #prev-text>-->
              <!--                          <feather-icon-->
              <!--                              icon="ChevronLeftIcon"-->
              <!--                              size="18"-->
              <!--                          />-->
              <!--                        </template>-->
              <!--                        <template #next-text>-->
              <!--                          <feather-icon-->
              <!--                              icon="ChevronRightIcon"-->
              <!--                              size="18"-->
              <!--                          />-->
              <!--                        </template>-->
              <!--                      </b-pagination>-->

              <!--                    </b-col>-->

              <!--                  </b-row>-->
              <!--                </div>-->
              <!--              </b-card>-->
            </b-col>

            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Course Classes</h5>
              <small class="text-muted">Enter The Classes Included In This Course.</small>

              <div class="d-inline-block float-right">
                <b-button @click="startCreateClass" variant="primary">Add New Class</b-button>
              </div>
            </b-col>
            <b-col md="12">
              <b-table small :fields="courseClassesFields" :items="courseClasses" responsive="sm">
                <!-- Column: Date of Class -->
                <template #cell(date)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">
                      {{ moment(data.item.date).format('MMMM Do YYYY (dddd)') }}
                    </span>
                  </div>
                </template>

                <!-- Column: Live Classes -->
                <template #cell(time)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">
                      {{ moment(data.item.timeStart, 'HH:mm:ss').format('hh:mm A') }}
                      -
                      {{ moment(data.item.timeEnd, 'HH:mm:ss').format('hh:mm A') }}
                    </span>
                  </div>
                </template>

                <!-- Column: Visibility -->
                <template #cell(type)="data">
                  <b-badge
                    pill
                    :variant="`light-${data.item.type === 'class' ? 'success' : 'warning'}`"
                    class="text-capitalize"
                  >
                    {{ data.item.type }}
                  </b-badge>
                </template>

                <!-- Column: Visibility -->
                <template #cell(location)="data">
                  <b-badge
                    pill
                    :variant="`light-${data.item.location === 'virtual' ? 'primary' : 'secondary'}`"
                    class="text-capitalize"
                  >
                    {{ data.item.location }}
                  </b-badge>
                </template>

                <!-- Column: Visibility -->
                <template #cell(visibility)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveClassVisibilityVariant(data.item.visibility)}`"
                    class="text-capitalize"
                  >
                    {{ data.item.visibility }}
                  </b-badge>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge pill :variant="`light-${resolveClassStatusVariant(data.item.status)}`" class="text-capitalize">
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <template #cell(instructor)="data">
                  <span v-if="data.value && data.value.name">{{ data.value.name }}</span>
                  <span v-else>No Instructor Assigned</span>
                </template>

                <!--                  &lt;!&ndash; A virtual composite column &ndash;&gt;-->
                <!--                  <template #cell(profiles)="data">-->
                <!--                    {{ data.value }} total-->
                <!--                  </template>-->

                <!--                  &lt;!&ndash; A virtual composite column &ndash;&gt;-->
                <!--                  <template #cell(classes)="data">-->
                <!--                    {{ data.value.length }} total-->
                <!--                  </template>-->

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item @click="startEditClass(data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="startDeleteClass(data.item)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- class edit -->
      <tab-content v-if="enableClassEdit" title="Edit Class" :before-change="validationFormClassEdit">
        <validation-observer ref="classEditRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Edit A Class</h5>
              <small class="text-muted">Enter The Details For This Class.</small>

              <div class="d-inline-block float-right">
                <b-button @click="cancelClassEdit" variant="outline-secondary" class="mr-1">
                  <feather-icon icon="ArrowLeftIcon" />
                  Return
                </b-button>

                <b-button @click="startEditClassSave" variant="primary">Modify Class Settings</b-button>
              </div>
            </b-col>

            <b-col md="6">
              <b-form-group label="Date" label-for="class-date">
                <validation-provider #default="{ errors }" name="Date" vid="Date" rules="required">
                  <b-form-datepicker
                    class="w-100"
                    id="class-date"
                    :value="editingClass.date"
                    @input="(val) => (editingClass.date = val)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <validation-provider #default="{ errors }" name="Start Time" rules="required">
                <b-form-group label="Start Time" label-for="class-start-time">
                  <b-form-timepicker v-model="editingClass.timeStart" locale="en" input-id="class-start-time" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider #default="{ errors }" name="End Time" rules="required">
                <b-form-group label="End Time" label-for="class-end-time">
                  <b-form-timepicker v-model="editingClass.timeEnd" locale="en" input-id="class-end-time" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Type" label-for="classType">
                <validation-provider #default="{ errors }" name="Type" vid="Type" rules="required">
                  <v-select
                    id="type"
                    v-model="editingClass.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classTypeOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Location" label-for="classLocation">
                <validation-provider #default="{ errors }" name="Location" vid="Location" rules="required">
                  <v-select
                    id="type"
                    v-model="editingClass.location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classLocationOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Status" label-for="classStatus">
                <validation-provider #default="{ errors }" name="Status" vid="Status" rules="required">
                  <v-select
                    id="status"
                    v-model="editingClass.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classStatusOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Visibility" label-for="courseVisibility">
                <validation-provider #default="{ errors }" name="Visibility" rules="required">
                  <v-select
                    id="classVisibility"
                    v-model="editingClass.visibility"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classVisibilityOptions"
                    :selectable="(option) => !option.value.includes('select_value')"
                    :clearable="false"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BTable,
  BProgress,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BFormSelect,
  BFormTimepicker,
  BFormDatepicker,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import rosterStoreModule from '../rosterStoreModule';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import ClassList from '@/views/apps/roster/classes-list/ClassList';
import flatPickr from 'vue-flatpickr-component';
import useClassesList from '../classes-list/useClassesList';
import moment from 'moment';
import { avatarText, title } from '@core/utils/filter';

// TODO: Don't duplicate courses and classes, should be created from scratch on each button click
export default {
  components: {
    ClassList,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormSelect,
    BFormTimepicker,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      searchQuery: undefined,
      perPage: 10,
      perPageOptions: [3, 5, 10, 15, 25],

      navHidden: false,

      // Session Details
      editingSession: {
        name: undefined,
        status: { text: 'Created', value: 'created' },
        visibility: { text: 'Public', value: 'internal' },
      },

      // Session Courses
      sessionCourses: [],

      // Course Create
      enableCourseCreate: false,

      courseStatusOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Created', value: 'created' },
        { text: 'Waiting', value: 'waiting' },
        { text: 'Live', value: 'live' },
        { text: 'Completed', value: 'completed' },
      ],
      courseVisibilityOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Internal', value: 'internal' },
        { text: 'Public', value: 'public' },
      ],

      // Course Edit
      enableCourseEdit: false,
      originalEditingCourse: {},
      emptyCourse: { courses: [], visibility: 'public', status: 'waiting' },
      editingCourse: { classes: [] },
      courseClasses: [],

      courseClassesFields: [
        { key: 'date', label: 'Date' },
        { key: 'time', label: 'Time' },
        { key: 'type', label: 'Class Type' },
        { key: 'location', label: 'Location' },
        { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      // Class Create
      enableClassCreate: false,

      classTypeOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Class', value: 'class' },
        { text: 'Lab', value: 'lab' },
      ],
      classLocationOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Physical', value: 'physical' },
        { text: 'Virtual', value: 'virtual' },
      ],
      classStatusOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Created', value: 'created' },
        { text: 'Waiting', value: 'waiting' },
        { text: 'Live', value: 'live' },
        { text: 'Completed', value: 'completed' },
      ],
      classVisibilityOptions: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Internal', value: 'internal' },
        { text: 'Public', value: 'public' },
      ],

      // Class Edit
      enableClassEdit: false,
      originalClassCourse: {},
      emptyClass: {
        date: new Date(),
        timeStart: '08:00:00',
        timeEnd: '18:00:00',
        type: 'class',
        location: 'virtual',
        status: 'waiting',
        visibility: 'public',
      },
      editingClass: {},

      // Misc

      sessionStatus: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Created', value: 'created' },
        { text: 'Enrolling', value: 'enrolling' },
        { text: 'Waiting', value: 'waiting' },
        { text: 'Live', value: 'live' },
        { text: 'Completed', value: 'completed' },
      ],
      sessionVisibility: [
        { text: 'Select Value', value: 'select_value' },
        { text: 'Internal', value: 'internal' },
        { text: 'Public', value: 'public' },
      ],

      sessionCoursesFields: [
        { key: 'name', label: 'Name' },
        { key: 'instructor', label: 'Instructor' },
        { key: 'profiles', label: 'Students' },
        { key: 'classes', label: 'Classes' },
        { key: 'operation', label: 'Op' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  mounted() {},
  methods: {
    startCreateClass() {
      return this.startEditClass({ ...this.emptyClass });
    },
    startCreateCourse() {
      return this.startEditCourse({ ...this.emptyCourse });
      this.enableCourseEdit = false;

      this.enableCourseCreate = true;
      this.$refs.refFormWizard.nextTab();
    },
    startPreviewCourse(course) {},
    startEditClass(clazz) {
      this.navHidden = true;
      this.enableClassCreate = false;

      this.enableClassEdit = true;
      this.originalEditingClass = clazz;
      this.editingClass = clazz ? { ...clazz } : { ...this.emptyClass };

      let fields = ['type', 'location', 'visibility', 'status'];
      for (let field of fields) {
        this.editingClass[field] = {
          text: title(this.editingClass[field]),
          value: this.editingClass[field],
        };
      }

      this.$refs.refFormWizard.nextTab();
    },
    startEditCourse(course) {
      console.log(`starting edit >`, course);
      this.navHidden = true;
      this.enableCourseCreate = false;

      this.enableCourseEdit = true;
      this.originalEditingCourse = course;
      this.editingCourse = course ? { ...course } : { ...this.emptyCourse };

      let fields = ['visibility', 'status'];
      for (let field of fields) {
        this.editingCourse[field] = {
          text: title(this.editingCourse[field]),
          value: this.editingCourse[field],
        };
      }

      if (this.editingCourse.subject) {
        let matched = this.subjectData.filter((subject) => subject.id === this.editingCourse.subject);

        if (matched && matched.length > 0) {
          this.editingCourse.subject = { text: matched[0].name, value: matched[0].id };
        }
      }

      if (this.editingCourse.classes) {
        this.courseClasses = this.editingCourse.classes;
      }

      this.$refs.refFormWizard.nextTab();
    },
    async startEditClassSave() {
      if (!(await this.validationFormClassEdit())) return;

      if (this.originalEditingClass) {
        this.startDeleteClass(this.originalEditingClass);
        this.originalEditingClass = undefined;
      }

      let fields = ['type', 'location', 'visibility', 'status'];
      for (let field of fields) {
        if (this.editingClass[field] && this.editingClass[field].value) {
          this.editingClass[field] = this.editingClass[field].value;
        }
      }

      this.courseClasses.push(this.editingClass);
      this.editingClass = {};

      this.cancelClassEdit();
    },
    async startEditCourseSave() {
      if (!(await this.validationFormCourseEdit())) return;

      if (this.originalEditingCourse) {
        this.startDeleteCourse(this.originalEditingCourse);
        this.originalEditingCourse = undefined;
      }

      let fields = ['subject', 'visibility', 'status'];
      for (let field of fields) {
        if (this.editingCourse[field] && this.editingCourse[field].value) {
          this.editingCourse[field] = this.editingCourse[field].value;
        }
      }

      if (this.courseClasses) {
        this.editingCourse.classes = this.courseClasses;
      }

      this.sessionCourses.push(this.editingCourse);
      this.editingCourse = {};

      this.cancelCourseEdit();
    },
    cancelClassEdit() {
      this.$refs.refFormWizard.navigateToTab(2);
      this.enableClassEdit = false;
    },
    cancelCourseEdit() {
      if (this.enableClassEdit) {
        this.cancelClassEdit();
      }

      this.$refs.refFormWizard.navigateToTab(1);
      this.enableCourseEdit = false;
      this.navHidden = false;
    },
    startDeleteClass(clazz) {
      this.courseClasses = this.courseClasses.filter((a) => a !== clazz);
      return this.courseClasses;
    },
    startDeleteCourse(course) {
      this.sessionCourses = this.sessionCourses.filter((a) => a !== course);
    },

    async formSubmitted() {
      let session = {
        name: this.editingSession.name,
        status: this.editingSession.status.value,
        visibility: this.editingSession.visibility.value,
      };

      console.log(`Session to Create >`, session);

      let courses = this.sessionCourses.map((course) => {
        return {
          name: course.name,
          instructor: course.instructor && course.instructor.id ? course.instructor.id : null,
          profiles: course.profiles,
          classes: course.classes,
          subject: course.subject,
          visibility: course.visibility,
          status: course.status,
        };
      });

      if (courses.length === 0) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to create session',
            text: 'You must add at least 1 course to create using the interactive builder.',
            icon: 'EditIcon',
            variant: 'error',
          },
        });
      }

      let kCourseIds = [];

      for await (let course of courses) {
        let kClassIds = [];

        for await (let clazz of course.classes) {
          const kClass = await store.dispatch('app-roster/addClass', { classData: clazz }).catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to create class',
                text: error.response ? error.response.data.message : error.message,
                icon: 'EditIcon',
                variant: 'error',
              },
            });
          });

          if (!kClass.data || !kClass.data.id) continue;

          kClassIds.push(kClass.data.id);
        }

        course.classes = kClassIds;

        const kCourse = await store.dispatch('app-roster/addCourse', { courseData: course }).catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create course',
              text: error.response ? error.response.data.message : error.message,
              icon: 'EditIcon',
              variant: 'error',
            },
          });
        });

        if (!kCourse.data || !kCourse.data.id) continue;

        kCourseIds.push(kCourse.data.id);
      }

      session.courses = kCourseIds;

      const kSession = await store.dispatch('app-roster/addSession', { sessionData: session }).catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to create session',
            text: error.response ? error.response.data.message : error.message,
            icon: 'EditIcon',
            variant: 'error',
          },
        });
      });

      if (!kSession.data || !kSession.data.id) return;
      console.log(`Created Session > `, kSession.data);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Session Created!',
          icon: 'EditIcon',
          variant: 'success',
        },
      });

      const kPathway = await store
        .dispatch('app-roster/addSessionToPathway', {
          pathwayId: this.pathwayId,
          sessionId: kSession.data.id,
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add session to pathway',
              text: error.response ? error.response.data.message : error.message,
              icon: 'EditIcon',
              variant: 'error',
            },
          });
        });

      if (!kPathway.data || kPathway.status !== 200) return;

      router.push({ name: 'apps-roster-session-view', params: { id: kSession.data.id } });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.sessionRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormCourseCreate() {
      return new Promise((resolve, reject) => {
        this.$refs.courseCreateRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormClassEdit() {
      return new Promise((resolve, reject) => {
        this.$refs.courseEditRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormCourseEdit() {
      return new Promise((resolve, reject) => {
        this.$refs.courseEditRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  computed: {
    pathwayId() {
      return this.$route.params.id;
    },
    coursesSubjects() {
      if (!this.subjectData) return [];

      return this.subjectData.map((subject) => {
        return {
          value: subject.id,
          text: subject.name,
        };
      });
    },
  },
  setup() {
    const subjectData = ref([]);

    const PATHWAY_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) {
      store.registerModule(PATHWAY_APP_STORE_MODULE_NAME, rosterStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PATHWAY_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch('app-roster/fetchSubjects', { limit: 100 })
      .then((response) => {
        subjectData.value = response.data.results;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          subjectData.value = undefined;
        }
      });

    const {
      fetchClasses,
      tableColumns,
      perPage,
      currentPage,
      totalClasses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClassListTable,
      refetchData,

      statusOptions,
      visibilityOptions,

      refCourseId,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useClassesList();

    return {
      subjectData,

      title,
      moment,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,
    };
  },
};
</script>
